import styled from 'styled-components';

export interface ScTitleProps {
	color?: string;
	isMobileLarge?: boolean;
	centered?: boolean;
}

export const ScTitleSmall = styled.h3<ScTitleProps>`
	color: ${({ theme, color }) => color || theme.colors.darkText};
	text-align: ${({ centered }) => centered && 'center'};
	margin: 0;
	font: ${({ theme, isMobileLarge }) =>
	isMobileLarge ? theme.fonts.heading.small : theme.fonts.heading.xs};
	@media (min-width: 768px) {
		font: ${({ theme }) => theme.fonts.heading.cardSmall};
	}
	@media (min-width: 1220px) {
		font: ${({ theme }) => theme.fonts.heading.cardMedium};
	}
	@media (min-width: 1440px) {
		font: ${({ theme }) => theme.fonts.heading.cardMedium};
	}
	@media (min-width: 1920px) {
		font: ${({ theme }) => theme.fonts.heading.mediumLargeBold};
	}
`;

export const ScTitleMedium = styled.h4<ScTitleProps>`
	color: ${({ theme, color }) => color || theme.colors.darkText};
	text-align: ${({ centered }) => centered && 'center'};
	margin: 0;
	font: ${({ theme, isMobileLarge }) =>
		isMobileLarge ? theme.fonts.heading.small : theme.fonts.heading.mediumPlus};
	@media (min-width: 768px) {
		font: ${({ theme }) => theme.fonts.heading.large};
	}
	@media (min-width: 1220px) {
		font: ${({ theme }) => theme.fonts.heading.enormous};
	}
	@media (min-width: 1440px) {
		font: ${({ theme }) => theme.fonts.heading.xl};
	}
	@media (min-width: 1920px) {
		font: ${({ theme }) => theme.fonts.heading.xl};
	}
`;

export const ScTitle = styled.h2<ScTitleProps>`
	color: ${({ theme, color }) => color || theme.colors.darkText};
	text-align: ${({ centered }) => centered && 'center'};
	margin: 0;
	font: ${({ theme, isMobileLarge }) =>
		isMobileLarge ? theme.fonts.heading.small : theme.fonts.heading.xs};
	@media (min-width: 768px) {
		font: ${({ theme }) => theme.fonts.heading.medium};
	}
	@media (min-width: 1220px) {
		font: ${({ theme }) => theme.fonts.heading.large};
	}
	@media (min-width: 1440px) {
		font: ${({ theme }) => theme.fonts.heading.enormous};
	}
	@media (min-width: 1920px) {
		font: ${({ theme }) => theme.fonts.heading.xl};
	}
`;

export const ScMainTitle = styled.h1<ScTitleProps>`
	color: ${({ theme, color }) => color || theme.colors.darkText};
	text-align: ${({ centered }) => centered && 'center'};
	margin: 0;
	font: ${({ theme, isMobileLarge }) =>
		isMobileLarge ? theme.fonts.heading.small : theme.fonts.heading.xs};
	@media (min-width: 768px) {
		font: ${({ theme }) => theme.fonts.heading.medium};
	}
	@media (min-width: 1220px) {
		font: ${({ theme }) => theme.fonts.heading.large};
	}
	@media (min-width: 1440px) {
		font: ${({ theme }) => theme.fonts.heading.enormous};
	}
	@media (min-width: 1920px) {
		font: ${({ theme }) => theme.fonts.heading.xl};
	}
`;

export const ScLargeTitle = styled.h1<ScTitleProps>`
	color: ${({ theme, color }) => color || theme.colors.darkText};
	text-align: ${({ centered }) => centered && 'center'};
	margin: 0;
	letter-spacing: -3.534px;
	font: ${({ theme }) => theme.fonts.largeHeading.small};
	@media (min-width: 768px) {
		font: ${({ theme }) => theme.fonts.largeHeading.medium};
	}
	@media (min-width: 1220px) {
		font: ${({ theme }) => theme.fonts.largeHeading.large};
		letter-spacing: -9.6px;
	}
	@media (min-width: 1920px) {
		font: ${({ theme }) => theme.fonts.largeHeading.xl};
	}
`;

export const ScGogetaNurseryExplainedWrapper = styled.div<ScTitleProps>`
	display: flex;
	flex-direction: column;
	gap: 30px;
	margin-top: 20px;
	@media (min-width: 1220px) {
		gap: 100px;
		margin-top: 50px;
	}
`;
